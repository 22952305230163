import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import BurgerMenu from './BurgerMenu'
import DefaultMenu from './DefaultMenu'

const MenuBackground = styled.div`
  display: flex;
  position: fixed;
  margin-top: -75px;
  right: -60px;
  min-height: 150px;
  width: 150px;
  border-radius: 75px;
  z-index: 2;
  background-color: transparent;
`

const Menu = ({ langKey, color }) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 920px)',
  })

  const result = useStaticQuery(graphql`
    {
      allContentfulTranslation {
        edges {
          node {
            chooseUsTitle
            servicesTitle
            orderTitle
            industriesTitle
            partnersTitle
            language {
              key
            }
          }
        }
      }
    }
  `)

  if (!result.allContentfulTranslation.edges) return null

  const translation = result.allContentfulTranslation.edges.find(
    ({ node }) => node.language.key === langKey
  )

  if (!translation) return null

  const {
    chooseUsTitle,
    servicesTitle,
    orderTitle,
    industriesTitle,
    partnersTitle,
  } = translation.node

  if (isMobile) {
    return (
      <>
        <MenuBackground color={color}></MenuBackground>
        <BurgerMenu
          services={servicesTitle}
          order={orderTitle}
          chooseUs={chooseUsTitle}
          industries={industriesTitle}
          partners={partnersTitle}
          color={color}
        />
      </>
    )
  }

  return (
    <DefaultMenu
      services={servicesTitle}
      order={orderTitle}
      chooseUs={chooseUsTitle}
      industries={industriesTitle}
      partners={partnersTitle}
    />
  )
}

export default Menu
