import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.footer`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-color: #232323;
  max-width: ${props => props.theme.sizes.maxWidth};
`

const Text = styled.p`
  color: ${props => props.theme.colors.gray};
  margin: 20px;
`

const Footer = () => (
  <Wrapper>
  </Wrapper>
)

export default Footer
