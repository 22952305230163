import { elastic as Menu } from 'react-burger-menu'
import React from 'react'
import styled from 'styled-components'
import { Location } from '@reach/router'
import { Link } from 'gatsby'

const List = styled.ul`
  display: flex !important;
  flex-direction: row;
  li {
    color: white !important;
    margin: 4px;
    font-size: 18px;
  }

  a {
    margin-right: 4px;
    text-decoration: none;
    color: white;
    line-height: 3;
    vertical-align: center;
    white-space: nowrap;
    font-weight: 400;
    font-size: 20px;
    text-transform: uppercase;
    transition: all 0.2s;
    &:hover {
      color: ${props => props.theme.colors.purple};
    }
    &:active {
      color: ${props => props.theme.colors.purple};
    }
  }
`

const MenuItem = styled(Link)`
  color: white;
  margin: 0;
`
const MenuItemAncor = styled.a`
  color: white;
  margin: 0;
`

class BurgerMenu extends React.Component {
  constructor(props) {
    super(props)
  }
  showSettings(event) {
    event.preventDefault()
  }

  render() {
    const { services, chooseUs, order, industries, partners, color } = this.props

    let styles = {
      bmBurgerButton: {
        position: 'fixed',
        width: '38px',
        height: '28px',
        right: '16px',
        top: '16px',
        transform: 'scale(0.8)',
      },
      bmBurgerBars: {
        background: 'rgb(101, 84, 192)',
      },
      bmBurgerBarsHover: {
        background: '#a90000',
      },
      bmCrossButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '20px',
        transform: 'scale(1.7)',
        height: '44px',
        width: '44px',
      },
      Button: {
        height: '64px',
        width: '64px',
      },
      bmCross: {
        background: '#fff',
      },
      bmMenuWrap: {
        position: 'fixed',
        height: '100%',
      },
      bmMenu: {
        background: color,
        padding: '2.5em 1.5em 0',
        fontSize: '1.15em',
      },
      bmMorphShape: {
        fill: color,
        width: '100%',
      },
      bmItemList: {
        display: 'flex',
        color: '#b8b7ad',
        padding: '0.8em',
        flexDirection: 'column',
      },
      bmItem: {
        textTransform: 'uppercase',
        display: 'inline-block',
        margin: '20px',
        textDecoration: 'none',
        color: '#fff',
        fontWeight: '400',
        fontSize: '18px',
      },
      bmOverlay: {
        background: 'rgba(0, 0, 0, 0.3)',
      },
    }
    return (
      <Location>
        {({ location }) => {
          const localizedServicePath = location.pathname
            .split('/')
            .slice(0, 3)
            .join('/')
          const isBusinessPath = localizedServicePath.includes('business')

          return (
            <Menu right styles={styles} color={color}>
              <MenuItem to={`${localizedServicePath}#services`}>
                {services}
              </MenuItem>
              <MenuItem to={`${localizedServicePath}#partners`}>
                {partners}
              </MenuItem>
              {isBusinessPath && (
                <MenuItem to={`${localizedServicePath}#industries`}>
                  {industries}
                </MenuItem>
              )}
              <MenuItem to={`${location.pathname}#choose-us`}>
                {chooseUs}
              </MenuItem>
              <MenuItem to={`${location.pathname}#order`}>{order}</MenuItem>
              <MenuItemAncor
                target="_blank"
                href="https://shop.motmould.com"
              >
                Online Store
              </MenuItemAncor>
              <List>
                <li>
                  <MenuItem to="/hu">HU</MenuItem>
                </li>
                <li>
                  <MenuItem to="/de">DE</MenuItem>
                </li>
                <li>
                  <MenuItem to="/en">EN</MenuItem>
                </li>
                <li>
                  <MenuItem to="/ro">RO</MenuItem>
                </li>
              </List>
            </Menu>
          )
        }}
      </Location>
    )
  }
}

export default BurgerMenu
