import { Location } from '@reach/router'
import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Logo from '../images/logo.svg'

const Header = styled.header`
  position: fixed;
  background: #333;
  width: 100%;
  padding: 0.5em 0;
`

const Nav = styled.nav`
  display: flex;
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0;
  padding: 0 1.5em;
  justify-content: space-between;

  ul {
    display: flex;
  }

  li {
    display: flex;
    margin-left: 1em;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #bbb;
  line-height: 3;
  vertical-align: center;
  white-space: nowrap;
  font-weight: 400;
  font-size: 15px;
  text-transform: uppercase;
  transition: all 0.2s;
  margin: 8px 12px;
  &:hover {
    color: ${props => props.theme.colors.magenta};
  }
`
const StyledAncor = styled.a`
  text-decoration: none;
  color: #fff;
  background: #6554c0;
  line-height: 3;
  vertical-align: center;
  white-space: nowrap;
  font-weight: bold;
  font-size: 15px;
  padding: 0% 7%;
  text-transform: uppercase, bold;
  transition: all 0.2s;
  margin: 8px 12px;
  border-radius: 100px;
  &:hover {
    color: ${props => props.theme.colors.magenta};
  }
  border: 3px solid #6554c0;
`

const LanguagesContainer = styled.div`
  display: flex;
  align-items: bottom;
  justify-content: flex-end;
  width: 200px;
`

const activeLinkStyle = {
  color: '#f3487d',
}

const DefaultMenu = ({ services, chooseUs, order, industries, partners }) => {
  return (
    <Location>
      {({ location }) => {
        const localizedServicePath = location.pathname
          .split('/')
          .slice(0, 3)
          .join('/')

        return (
          <Header style={{ zIndex: 5 }}>
            <Nav>
              <ul>
                <li>
                  <StyledLink to="/" activeStyle={activeLinkStyle}>
                    <img src={Logo} style={{ width: 40 }} alt="Logo"></img>
                  </StyledLink>
                </li>
                <li>
                  <StyledLink
                    to={`${localizedServicePath}#services`}
                    activeStyle={activeLinkStyle}
                  >
                    {services}
                  </StyledLink>

                  <StyledLink
                    to={`${localizedServicePath}#partners`}
                    activeStyle={activeLinkStyle}
                  >
                    {partners}
                  </StyledLink>

                  <StyledLink
                    to={`${localizedServicePath}#industries`}
                    activeStyle={activeLinkStyle}
                  >
                    {industries}
                  </StyledLink>

                  <StyledLink
                    to={`${location.pathname}#choose-us`}
                    activeStyle={activeLinkStyle}
                  >
                    {chooseUs}
                  </StyledLink>
                  <StyledLink
                    to={`${location.pathname}#order`}
                    activeStyle={activeLinkStyle}
                  >
                    {order}
                  </StyledLink>
                </li>
              </ul>
              <LanguagesContainer>
                <StyledAncor
                  href="https://shop.motmould.com"
                  target="_blank"
                  activeStyle={activeLinkStyle}
                >
                  Online Store
                </StyledAncor>
                <StyledLink to="/hu" activeStyle={activeLinkStyle}>
                  HU
                </StyledLink>
                <StyledLink to="/de" activeStyle={activeLinkStyle}>
                  DE
                </StyledLink>
                <StyledLink to="/en" activeStyle={activeLinkStyle}>
                  EN
                </StyledLink>
                <StyledLink to="/ro" activeStyle={activeLinkStyle}>
                  RO
                </StyledLink>
              </LanguagesContainer>
            </Nav>
          </Header>
        )
      }}
    </Location>
  )
}

export default DefaultMenu
