import React from 'react'
import Helmet from 'react-helmet'
import styled, { ThemeProvider } from 'styled-components'
import Footer from '../components/Footer'
import favicon from '../images/favicon.ico'
import GlobalStyle from '../styles/global'
import theme from '../styles/theme'
import Menu from './Menu'

const Layout = ({ children, langKey, color }) => {
  // Smooth scrolling for #
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require
    require('smooth-scroll')('a[href*="#"]', {
      speed: 500,
      speedAsDuration: true,
      offset: 80,
    })
  }

  return (
    <div className="siteRoot">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href={favicon} />
      </Helmet>

      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <>
          <div className="siteContent">
            <Menu langKey={langKey} color={color} />
            <Gap />
            {children}
          </div>
          <Footer />
        </>
      </ThemeProvider>
    </div>
  )
}

const Gap = styled.div`
  height: 55px;
  @media only screen and (max-width: ${props =>
      props.theme.responsive.medium}) {
    height: 0;
  }
`

export default Layout
