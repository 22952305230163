const theme = {
  colors: {
    base: '#232323',
    secondary: '#e9e9e9',
    lightPurple: '#c8c0f2',
    purple: '#6554C0',
    darkPurple: '#5242A3',
    white: '#F6F6F6',
    lightMagenta: '#FDA2BE',
    magenta: '#F3487D',
    highlight: '#5b8bf7',
    gray: '#565656',
    darkGray: '#333333',
  },
  sizes: {
    maxWidthCentered: '650px',
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '70em',
  },
}

export default theme
